//@ts-nocheck
import React, { useEffect, useState } from 'react';

import {
  Grid,
  Container,
  Wrapper,
  Icon,
  WrapperIcon,
  WrapperLocation,
  WrapperApp,
  Wrapper1,
  WrapperCopy,
  Text,
  Hr,
  WrapperButton,
  ButtonGreen,
  ButtonPurple,
  Linkpdf,
} from './styles';

import facebook from '../../assets/images/medias/facebook.png';
import instagram from '../../assets/images/medias/insta.png';
import linkedin from '../../assets/images/medias/linkedin.png';
import tiktok from '../../assets/images/medias/TikTok-icon-white@2x.png';
import apple from '../../assets/images/apple.png';
import android from '../../assets/images/android.png';
import { Link } from 'react-router-dom';
import pdf from '../../assets/xgreen_termsPolicy_202405.pdf';
import maps from '../../assets/googleMaps_@2x.png';
const Footer: React.FC = () => {
  return (
    <Container>
      <Grid>
        <Wrapper style={{ width: '75%' }}>
          <h1>SOCIAL MEDIA</h1>
          <hr
            style={{
              border: '0.5px solid #FFFFFF',
              width: '100%',
              opacity: '0.5',
              textAlign: 'left',
            }}
          />

          <WrapperIcon>
            <Icon>
              <a href="https://www.linkedin.com/company/xgreen" target="_blank">
                <img src={linkedin} style={{ width: '22.5px' }} />
                <p>LinkedIn</p>
              </a>
            </Icon>

            <Icon>
              <a href="https://www.facebook.com/xgreenapp/" target="_blank">
                <img src={facebook} style={{ width: '11.37px' }} />
                <p>Facebook</p>
              </a>
            </Icon>

            <Icon>
              <a href=" https://www.instagram.com/xgreen.app" target="_blank">
                <img src={instagram} style={{ width: '22.78px' }} />
                <p>Instagram</p>
              </a>
            </Icon>

            <Icon>
              <a
                href="https://www.tiktok.com/@xgreenusa?is_from_webapp=1&sender_device=pc"
                target="_blank"
              >
                <img src={tiktok} style={{ width: '21.5px', height: '24px' }} />
                <p>TikTok</p>
              </a>
            </Icon>
          </WrapperIcon>
        </Wrapper>

        <Wrapper>
          <div style={{ flexDirection: 'row', display: 'flex', gap: '15px' }}>
            <img
              src={maps}
              onClick={() => {
                window.open(
                  'https://www.google.com/maps/place/Xgreen/@28.5388508,-81.3819487,17z/data=!3m1!4b1!4m6!3m5!1s0x43a52ec7841cc001:0xa8a9094e9918ad89!8m2!3d28.5388461!4d-81.3793684!16s%2Fg%2F11w8fbd1tl?entry=ttu&g_ep=EgoyMDI0MDgyNi4wIKXMDSoASAFQAw%3D%3D',
                  '_blank'
                );
              }}
              style={{ height: '23px', width: '23px', cursor: 'pointer' }}
            />
            <h1>HEADQUARTER</h1>
          </div>
          <hr
            style={{
              border: '0.5px solid #FFFFFF',
              width: '100%',
              opacity: '0.5',
            }}
          />

          <WrapperLocation>
            <h2>Orlando/FL - USA</h2>
            <span>
              300 S Orange Avenue #Ste 1000, Orlando, FL, 32801, USA.
              <br />
              Phone: +1 (689) 899-0285
            </span>
          </WrapperLocation>
        </Wrapper>

        <Wrapper1>
          <h1>BOOK IT</h1>
          <hr
            style={{
              border: '0.5px solid #FFFFFF',
              width: '100%',
              opacity: '0.5',
            }}
          />
          <WrapperButton>
            <Link
              to="/"
              onClick={() =>
                window.open('https://dashboard.xgreen.app', '_blank')
              }
            >
              <ButtonGreen>BOOK NOW</ButtonGreen>
            </Link>
            <Link to="/refer">
              <ButtonPurple>EARN $50</ButtonPurple>
            </Link>
          </WrapperButton>
        </Wrapper1>
      </Grid>
      <WrapperCopy style={{}}>
        <Hr />
        <Text>
          <p>Copyright © 2021 Xgreen. All rights reserved.</p>
          <p>
            <Linkpdf href={pdf} target="_blank">
              Terms and Conditions
            </Linkpdf>{' '}
            | <strong>Coverage Policy</strong>
          </p>
          <WrapperApp>
            <img
              src={android}
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.mindconsulting.xgreen&hl=en',
                  '_blank'
                );
              }}
            />
            <img
              src={apple}
              onClick={() => {
                window.open(
                  'https://apps.apple.com/br/app/xgreen/id6473839103',
                  '_blank'
                );
              }}
            />
          </WrapperApp>
        </Text>
      </WrapperCopy>
    </Container>
  );
};

export default Footer;
