import React from 'react';
// @ts-nocheck
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Service from './pages/Service';
import Faq from './pages/Faq';
import Work from './pages/Work';
import Costumer from './pages/Costumer';
import Goal from './pages/Goal';
import ScrollToTop from './components/ScrollToTop';
import Review from './pages/Review';
import Refer from './pages/Refer';
import Location from './pages/Location';
const Rotas = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/service" element={<Service />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/work" element={<Work />} />
        <Route path="/customer" element={<Costumer />} />
        <Route path="/goal" element={<Goal />} />
        {/* <Route path="/review" element={<Review />} /> */}
        <Route path="/refer" element={<Refer />} />
        <Route path="/location" element={<Location />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

export default Rotas;
