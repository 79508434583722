import React, { useState } from 'react';
import styled from 'styled-components';

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  margin-top: 50px;
  max-width: 70vw;
  @media screen and (max-width: 780px) {
    max-width: 100vw;
    padding: 0 5%;
  }
  margin-bottom: 30px;
`;

const TabButtons = styled.div`
  display: flex;
  justify-content: flex-start; /* Alinha as tabs à esquerda */
  margin-bottom: 16px;
  text-align: left;
`;

const TabButton = styled.button<{ active: boolean }>`
  margin-right: 130px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  font-family: ${({ active }) =>
    active ? 'avenir_nextbold' : 'avenir_nextregular'};
  font-size: 18px;
  padding: 8px 0; /* Remove o padding da esquerda */
  color: #000;
  line-height: 25px;
  &:hover {
    color: #666;
  }

  &:last-child {
    margin-right: 0; /* Remove o margin-right do último item */
  }

  @media screen and (max-width: 780px) {
    margin-right: 10px;
  }
`;

const TabContent = styled.div`
  padding: 0; /* Remove o padding de 16px */
`;

const Description = styled.p`
  margin: 16px 0;
  font-size: 20px;
  color: #000;
  font-family: 'avenir_nextregular';
  line-height: 26px;
`;

interface TabProps {
  label: string;
  description: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: TabProps[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <TabsContainer>
      <TabButtons>
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            active={activeTab === index}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabButtons>
      <TabContent>
        <Description>{tabs[activeTab].description}</Description>
        {tabs[activeTab].content}
      </TabContent>
    </TabsContainer>
  );
};

export default Tabs;
