import styled from 'styled-components';

type Props = {
  image: any;
  imageMobile: any;
};

export const IMG = styled.img`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: auto;
  margin-bottom: -4px;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    height: 400px;
  }
`;
