import styled from 'styled-components';

type Props = {
  image: any;
  imageMobile: any;
};
export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 15px;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #707070;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Wrapper = styled.div<Props>`
  padding-left: 15%;

  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    padding-left: 5%;
  }

  p:nth-of-type(1) {
    font-family: 'avenir_nextregular';
    font-size: 20px;
    text-align: left;
    line-height: 27px;
    color: #ffffff;
  }

  p:nth-of-type(2) {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    color: #9dfdca;
    margin: 0px;
    width: 550px;
    @media screen and (max-width: 1050px) {
      width: auto;
      font-size: 35px;
    }
  }
  p:nth-of-type(3) {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    color: #fff;
    margin: 0px;
    width: 550px;
    @media screen and (max-width: 1050px) {
      width: auto;
      font-size: 35px;
    }
  }
  p:nth-of-type(4) {
    font-family: 'avenir_nextregular';
    font-size: 20px;
    text-align: left;
    line-height: 26px;
    color: #fff;
    margin: 0px;
    width: 550px;
    @media screen and (max-width: 1050px) {
      width: auto;
      font-size: 35px;
    }
    margin-top: 26px;
  }
`;

export const WrapperIcon = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 0 15% 0 15%;

  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    padding: 30px 5%;
  }

  img {
    height: 36px;
    width: 36px;
  }

  p {
    margin: 0px;
    font-family: 'avenir_nextbold';
    font-size: 20px;
    text-align: left;
    line-height: 27px;
    color: #ffffff;
  }
  span {
    margin: 0px;
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    color: #ffffff;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WrapperApp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 30px;
  /* margin-right: 15px; */
  @media screen and (max-width: 780px) {
    img {
      width: 170px;
      margin-top: 20px;
    }
  }
`;
