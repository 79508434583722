//@ts-nocheck
import React, { useEffect, useState } from "react";

import { DivText, Grid, Container, PFirst, PSecond, Wrapper } from "./styles";

const Page1: React.FC = () => {
  return (
    <Container>
      <Grid>
        <Wrapper color="#9DFDCA">
          <PSecond>What differentiates us from others?</PSecond>
          <PFirst>
            We are concerned with every aspect of housework. We provide
            professionalism as well as property care. Insured and guaranteed
            results.
          </PFirst>
        </Wrapper>

        <Wrapper color="#FFFFFF">
          <DivText>
            <h4>FAQ about Cleaning Services</h4>
            <p>How many people are going to clean my house?</p>
            <span>
              Our cleaning crews work individually or in small groups of two.
              There is simply no other way to offer you a more personalized and
              private service.
            </span>
            <p>Are we insured?</p>
            <span>
              Compensation for workers is included in Xgreen’s insurance.
            </span>
            <p>Will I always be able to use the same Xgreen’s providers?</p>
            <span>
              We make every effort to send the same providers to your home. That
              is not always possible due to illness, vacations, or other
              obligations.
            </span>
          </DivText>
        </Wrapper>
      </Grid>
    </Container>
  );
};

export default Page1;
