//@ts-nocheck
import React from 'react'

import { DivText, Grid, Container, Wrapper } from './styles'

const Page4: React.FC = () => {
  return (
    <Container>
      <Grid>
        <DivText>
          <Wrapper>
            <p>
              Social impact is
              <br /> what we do!
            </p>
            <p>
              Xgreen works as a catalyst to improve social relationships in an
              atmosphere of opportunities. <br />
              <br />
              <strong>Output:</strong>Xgreen selects people who seek
              professional and financial growth and do not have the
              opportunities.
              <br />
              <br />
              <strong>Outcome:</strong>Xgreen’s goal is to provide financial
              growth and professional credentials that align with the needs of
              human development.
              <br />
              <br />
            </p>
            {/* <button
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.location.href = "/service";
              }}
            >
              Learn more about our offerings
            </button> */}
            <br />
          </Wrapper>
        </DivText>
      </Grid>
    </Container>
  )
}

export default Page4
