//@ts-nocheck
import React, { useEffect, useState } from 'react'

import { DivText, Grid, Container, Wrapper } from './styles'

const Page2: React.FC = () => {
  return (
    <Container>
      <Grid>
        <Wrapper color="transparent">
          <DivText fontColor="#000">
            <h4>hello@xgreen.app</h4>
            <p>
              Stay connected,
              <br /> if you have a question let us know.
            </p>
          </DivText>
        </Wrapper>
      </Grid>
    </Container>
  )
}

export default Page2
