//@ts-nocheck
import React from 'react';

import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import imgHome from '../../../assets/images/costumer/pic2.png';
import imgHomeMobile from '../../../assets/images/costumer/pic2.png';
import {
  Button,
  ComboInput,
  Container,
  Grid,
  Input,
  Wrapper,
  WrapperForm,
  Select,
  WrapperApp,
  ButtonGreen,
} from './styles';
import apple from '../../../assets/images/apple@2x.png';
import android from '../../../assets/images/android@2x.png';

const Page1: React.FC = () => {
  const recaptchaRef = React.createRef();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur', // "onChange"
  });

  const onSubmit = async (data) => {
    // const dados = {
    //   city: data.city,
    //   email: data.email,
    //   footage: data.footage,
    //   frequency: data.frequency,
    //   lastname: data.lastname,
    //   name: data.name,
    //   phone: data.phone,
    //   service: data.service,
    //   state: data.state,
    //   zipcode: data.zipcode,
    //   address: data.address,
    //   number: data.number,
    //   auth: '3f018bfb43591ccb2ef46f10de832160',
    // };

    const dados = {
      mensagem: `
      Name: ${data.name} // Last Name: ${data.lastname} // Email: ${data.email} // Phone: ${data.phone} // City: ${data.city} // State: ${data.state} // Zipcode: ${data.zipcode} //
      Street Address: ${data.address} // Number: ${data.number}
      // Square Footage: ${data.footage} // Service: ${data.service} 
      

    `,
      auth: '3f018bfb43591ccb2ef46f10de832160',
      assunto: 'Form Work Xgreen',
      destino: 'marcus.cominatto@gmail.com',
    };

    try {
      const response = await axios.post(
        'https://api.goey.com.br/ws/goey/rest/public/envioEmail',
        dados
      );

      if (parseInt(response.data.retorno) == 1) {
        alert('Sent with success!');
        setLoading(false);
        setCap(null);
        reset({
          name: '',
          city: '',
          email: '',
          footage: '',
          frequency: '',
          lastname: '',
          phone: '',
          service: '',
          state: '',
          zipcode: '',
          number: '',
          address: '',
        });
      } else {
        alert('Try again!');
      }
    } catch (error) {
      console.log(error);
    }
  };
  function onChange(value) {
    if (value == null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    setCap(value);
  }

  return (
    <Container>
      <Grid>
        <Wrapper image={imgHome} imageMobile={imgHomeMobile}></Wrapper>
        <WrapperForm>
          <h1>Schedule your cleaning now. Simple and quick.</h1>
          <p>
            There are many reasons to select and appreciate Xgreen. We work to
            create a customized plan for your needs.
          </p>
          <small>Download the App</small>
          <WrapperApp>
            <img
              src={android}
              style={{ height: '60px' }}
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.mindconsulting.xgreen&hl=en',
                  '_blank'
                );
              }}
            />
            <img
              src={apple}
              style={{ height: '60px' }}
              onClick={() => {
                window.open(
                  'https://apps.apple.com/br/app/xgreen/id6473839103',
                  '_blank'
                );
              }}
            />
          </WrapperApp>
          <small>or via web</small>
          <ButtonGreen
            onClick={() =>
              window.open('https://dashboard.xgreen.app', '_blank')
            }
          >
            START YOUR FREE ACCOUNT NOW
          </ButtonGreen>
        </WrapperForm>
        {/* <WrapperForm>
          <p>Get your quote.</p>
          <h1>Schedule your cleaning now. Simple and quick.</h1>

          <form onSubmit={handleSubmit(onSubmit)}>
            <ComboInput style={{ marginTop: '30px' }}>
              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="NAME"
                type="text"
                {...register('name', { required: true })}
              />
          
              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="LAST NAME"
                type="text"
                {...register('lastname', { required: true })}
              />
            </ComboInput>

            <ComboInput>
              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="EMAIL"
                type="email"
                {...register('email', { required: true })}
              />
            </ComboInput>

            <ComboInput>
              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="PHONE"
                type="tel"
                style={{ width: '50%' }}
                {...register('phone', { required: true })}
              />
            </ComboInput>
            <h3>ABOUT YOUR HOME</h3>
            <ComboInput>
              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="FOOTAGE"
                type="tel"
                {...register('footage', { required: true })}
              />

              <Select
                {...register('service', { required: true })}
                onChange={(e) =>
                  console.log(e.nativeEvent.target.selectedIndex.text)
                }
              >
                <option value="" hidden>
                  SERVICE
                </option>
                <option value="House Cleaning (regular)">
                  House Cleaning (regular)
                </option>
                <option value="House Cleaning (deep)">
                  House Cleaning (deep)
                </option>
              </Select>
            </ComboInput>
            <ComboInput>
              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="CITY"
                type="text"
                style={{ width: '50%' }}
                {...register('city', { required: true })}
              />
              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="ZIP"
                type="tel"
                style={{ width: '33%' }}
                {...register('zipcode', { required: true })}
              />
              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="STATE"
                type="text"
                style={{ width: '17%' }}
                {...register('state', { required: true })}
              />
            </ComboInput>
            <ComboInput>
            

              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="ADDRESS"
                type="text"
                {...register('address', { required: true })}
              />

              <Input
                onChange={(e) => console.log(e.target.value)}
                placeholder="Nº"
                type="tel"
                {...register('number', { required: true })}
              />
            </ComboInput>
            <ReCAPTCHA
              sitekey="6LfRTLQZAAAAAIypmq1Ag9DSVvpmo_aU9APEondj"
              onChange={onChange}
              ref={recaptchaRef}
              style={{ marginTop: '20px', width: '100% !important' }}
            />
            <ComboInput style={{ justifyContent: 'flex-start' }}>
              <Button>BOOK NOW</Button>
            </ComboInput>
          </form>
        </WrapperForm> */}
      </Grid>
    </Container>
  );
};

export default Page1;
