import styled from 'styled-components';

type Props = {
  image: any;
};
export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 15px;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #000;
  justify-content: center;
  align-items: center;

  div:nth-of-type(1) {
    justify-content: flex-end;
  }
  @media screen and (max-width: 1050px) {
    p {
      display: block;
      font-family: 'avenir_nextbold';
      font-size: 45px;
      text-align: left;
      line-height: 50px;
      margin: 30px 10px 0 10px;
    }
    grid-gap: 5px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    div:nth-of-type(1) {
      order: 2;
    }
    div:nth-of-type(2) {
      order: 1;
    }
  }
`;

export const Wrapper = styled.div<Props>`
  padding-left: 15%;

  min-height: 554px;

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: left;
  background-size: 418px 418px;
  @media screen and (max-width: 1050px) {
    background-position: center;
    padding: 0px;
    background-size: 272px 272px;
    min-height: 280px;
    margin: 0px;
  }
`;
export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  h1 {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    width: 429px;

    color: #fff;
  }
  span {
    color: #fff;
    width: 429px;
  }
  small {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    color: #fff;
    width: 429px;
  }

  @media screen and (max-width: 1050px) {
    width: 90%;
    margin-left: 3%;
    height: 75%;
  }
`;
export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 427px;
  h1 {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    margin: 0px;
  }

  span {
    font-family: 'avenir_nextregular';
    font-size: 35px;
    text-align: left;
    line-height: 40px;
    margin-top: 10px;
  }

  small {
    margin-top: 20px;
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
  }

  p {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    text-align: left;
    line-height: 27px;
    color: #fff;
    width: 100%;
    a {
      color: #fff !important;
      text-decoration: underline !important;
    }
  }

  @media screen and (max-width: 780px) {
    width: 100%;
    span {
      width: 100%;
      margin-top: 25px;
    }
    h1 {
      width: 100%;
      margin-top: 50px;
    }
    small {
      width: 100%;
    }
  }
`;
