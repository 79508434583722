import styled from 'styled-components'

type Props = {
  image: any
  imageMobile: any
}
export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 58% 38%;
  grid-gap: 15px;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #9dfdca;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Wrapper = styled.div<Props>`
  padding-left: 15%;

  @media screen and (max-width: 1050px) {
    padding: 3%;
  }

  p:nth-of-type(1) {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    color: #000;
    margin: 0px;
    width: 550px;
    @media screen and (max-width: 1050px) {
      width: auto;
    }

    @media screen and (max-width: 768px) {
      font-size: 36px !important;
      margin-top: 40px !important;
    }
  }
  p:nth-of-type(2) {
    font-family: 'avenir_nextbold';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    color: #000;
    margin-top: 20px;
  }
`

export const WrapperIcon = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 0 15% 0 15%;
  margin-bottom: 50px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    padding: 30px 3%;
  }

  img {
    height: 36px;
    width: 36px;
  }

  p {
    margin: 0px;
    font-family: 'avenir_nextbold';
    font-size: 20px;
    text-align: left;
    line-height: 27px;
    color: #000;
  }
  span {
    margin: 0px;
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    color: #000;
    max-width: 318px;
  }
`
