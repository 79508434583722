import styled from 'styled-components'

type Props = {
  color: any
}
export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
  background-color: #f8f8f8 !important;
`

export const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: 50% 50%;
  grid-gap: 5px;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #f8f8f8 !important;

  /* position: relative; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;
  }
`

export const Wrapper = styled.div<Props>`
  background-color: ${(props) => props.color};
  border-radius: 25px;
  margin: 25px;
  min-height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
    /* padding:5% 3% 0 0; */
    padding: 0 20px 0 20px;
    margin: 10px;
  }
`

export const PFirst = styled.p`
  font-family: 'avenir_nextregular';
  font-size: 16px;
  /* padding-right: 150px; */
  max-width: 407px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
  }
`
export const PSecond = styled.p`
  font-family: 'avenir_nextbold';
  font-size: 50px;
  line-height: 50px;
  max-width: 407px;
  margin: 10px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    max-width: 375px;
    margin: 0px;
  }
`
export const DivText = styled.div`
  padding: 0 110px 0 110px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1050px) {
    padding: 0 20px 0 20px;
  }

  h4 {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    line-height: 27px;
    text-align: left;
  }
  p {
    font-family: 'avenir_nextbold';
    font-size: 16px;
    line-height: 26px;
    text-align: left;
  }
  span {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    line-height: 26px;
    text-align: left;
  }

  @media screen and (max-width: 780px) {
    margin-bottom: 20px;
  }
`
