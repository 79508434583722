/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
// import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import menu from '../../assets/images/Menu.svg';
import login from '../../assets/images/Login.svg';
import xgreen from '../../assets/images/Layer_1-2@2x.png';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  // MobileIcon,
  //   NavMenu,
  NavHam,
  NavLogin,
  NavMenu,
  NavItem,
  NavLinks,
  MobileIcon,
  WrapperButton,
  ButtonWhite,
  ButtonBlack,
  ButtonGreen,
  WrapperBar,
  BarMenu,
} from '../Navbar/NavbarElements';
import { FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ButtonPurple } from '../Footer/styles';
import icon from '../../assets/loginDashboard_.png'
//MENU
const Navbar = ({ toggle }) => {
  //FUNCAO SCROLL
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <img className="LogoPrincipal" src={xgreen} style={{ marginTop: '10px' }} />
            </NavLogo>
            {/* <NavHam >
              <img className="LogoPrincipal" src={menu} onClick={toggle} />
            </NavHam> */}

            <BarMenu>CALL FOR FREE ESTIMATE: +1 (689) 899-0285</BarMenu>

            <MobileIcon onClick={toggle}>
              <FaBars color='#fff' />
            </MobileIcon>

            {/* <NavMenu>
              <NavItem>
                <NavLinks to='/'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >About us</NavLinks>
              </NavItem>



              <NavItem>
                <NavLinks to='/service'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Services</NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks to='/customer'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Customers</NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks to='/work'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Providers</NavLinks>
              </NavItem>


              <NavItem>
                <NavLinks to='/goal'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Our goals</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/faq'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >FAQ</NavLinks>
              </NavItem>
            </NavMenu> */}
            <WrapperButton>
              <Link to='/customer'>
                <ButtonGreen onClick={() => window.open('https://dashboard.xgreen.app', "_blank")}>
                  BOOK NOW
                </ButtonGreen>
              </Link>
              <Link to='/refer'>
                <div style={{ minWidth: "206px" }}>
                  <ButtonPurple>
                    EARN $50
                  </ButtonPurple>
                </div>

              </Link>
              <Link to="/" onClick={() => window.open('https://dashboard.xgreen.app', "_blank")}>
                <img src={icon} style={{ width: '48px', height: '48px' }} />
              </Link>
            </WrapperButton>

          </NavbarContainer>

        </Nav>
        <WrapperBar>
          <BarMenu>CALL FOR FREE ESTIMATE: +1 (689) 899-0285</BarMenu>
          <>
            <NavMenu>
              <NavItem>
                <NavLinks to='/'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >About us</NavLinks>
              </NavItem>



              <NavItem>
                <NavLinks to='/service'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Services</NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks to='/location'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Locations</NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks to='/customer'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Customers</NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks to='/work'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Providers</NavLinks>
              </NavItem>


              <NavItem>
                <NavLinks to='/goal'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >Our goals</NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/faq'
                  //smoooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                >FAQ</NavLinks>
              </NavItem>
            </NavMenu>
          </>
        </WrapperBar>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;

//yarn add styled-components
//yarn add react-icons
//yarn add react-scroll
