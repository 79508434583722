import styled from 'styled-components';
import img from './purple.png';
type Props = {
  image: any;
};

export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 40vh !important;
  min-width: 100vw;
  background-color: #000;
  @media screen and (max-width: 1050px) {
    min-height: 50vh !important;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 15px;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  /* 
  
  div:nth-of-type(1) {
      justify-content: flex-end;
  } */

  @media screen and (max-width: 1050px) {
    grid-gap: 5px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  width: 100%;
  h1 {
    margin: 0px;
    font-size: 16px;
    color: #9dfdca;
    /* margin-bottom: 10px; */
    line-height: 26px;
    font-family: 'avenir_nextbold';
  }

  @media screen and (max-width: 1050px) {
    /* margin-left: 34px; */
    /* width: 100%; */
    margin: 0px;
    padding: 20px !important;
    /* width: 100% !important; */
    /* margin-right: 25px; */
    width: auto;
  }
`;

export const Wrapper1 = styled.div<Props>`
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  margin-left: 5%;
  margin-right: 15px;
  h1 {
    margin: 0px;
    font-size: 16px;
    color: #9dfdca;
    /* margin-bottom: 10px; */
    line-height: 26px;
    font-family: 'avenir_nextbold';
  }

  @media screen and (max-width: 1050px) {
    /* margin-left: 34px; */
    /* width: 100%; */
    margin: 0px;
    padding: 20px;
  }
`;

export const WrapperIcon = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 10px;
`;
export const WrapperLocation = styled.div<Props>`
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  h2 {
    color: #fff;
    margin: 0px;
    font-family: 'avenir_nextbold';
    font-size: 20px;
    line-height: 27px;
  }
  span {
    color: #fff;
    font-family: 'avenir_nextregular';
    font-size: 16px;
    line-height: 26px;
  }

  @media screen and (max-width: 1050px) {
    h2 {
      font-family: 'avenir_nextbold';
      font-size: 14px;
      letter-spacing: -0.35pt;
      line-height: 24px;
    }
    span {
      font-family: 'avenir_nextregular';
      font-size: 14px;
      letter-spacing: -0.35pt;
      line-height: 24px;
    }
  }
`;
export const Icon = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: row;

  width: 25%;
  img {
    align-self: center;
    justify-content: center;
    align-items: center;
    /* width: 22.5px; */
  }
  a {
    display: flex;
    flex-direction: column;
  }
  a p {
    justify-content: center;
    align-self: center;
    margin: 0px;
    font-size: 14px;
    color: #fff;
    font-family: 'avenir_nextregular';
    letter-spacing: -0.35px;
    line-height: 32px;
  }
`;

export const WrapperApp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 30px;
  /* margin-right: 15px; */
`;

export const WrapperCopy = styled.div`
  padding: 0 20px;

  @media screen and (max-width: 1050px) {
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    font-size: 16px;
    font-family: 'avenir_nextregular';
    line-height: 24px;
    letter-spacing: -0.4px;
    color: #fff;
    strong {
      color: #9dfdca;
    }
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;

    p {
      margin: 5px 0 0 0;
    }

    p:nth-of-type(2) {
      margin-bottom: 50px;
    }
  }
`;

export const Hr = styled.hr`
  border: 0.5px solid #ffffff;
  width: 100%;
  opacity: 0.5;
  margin-top: 70px;
  @media screen and (max-width: 1050px) {
    margin-top: 20px;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  a {
    width: 100%;
  }
`;

export const ButtonWhite = styled.button`
  width: 100% !important;
  height: 45px;
  background-color: #ffffff;
  color: #000;
  border: transparent;
  font-family: 'avenir_nextbold';
  cursor: pointer;
`;
export const ButtonGreen = styled.button`
  width: 100%;
  height: 45px;
  background-color: #9dfdca;
  color: #000;
  border: transparent;
  font-family: 'avenir_nextbold';
  cursor: pointer;
  font-size: 16px;
  line-height: 26px;
`;

export const ButtonPurple = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  color: #9dfdca;
  opacity: 1;
  font-family: 'avenir_nextbold';
  cursor: pointer;
  z-index: 10000;
  border: none;
  cursor: pointer;
  font-size: 16px;
  button {
    font-family: 'avenir_nextbold' !important;
    font-size: 16px;
  }
`;

export const Linkpdf = styled.a`
  :hover {
    color: #fff !important;
    cursor: pointer;
  }
`;
