//@ts-nocheck
import React from 'react'

import { Grid, Container, Wrapper, WrapperForm, WrapperText } from './styles'
import imgHome from '../../../assets/images/goal/Layern.png'

const Page1: React.FC = () => {
  return (
    <Container>
      <Grid>
        <WrapperForm>
          <WrapperText>
            <h1>Our equipments</h1>
            <span>Great services with green equipments.</span>
            <small>
              <strong>Xgreen</strong> is sustainable cleaning. We use equipment,
              methods and products with environmentally friendly ingredients,
              and procedures which are designed to preserve human health and
              environmental quality.
            </small>
            <p>
              __ Learn more{' '}
              <a href="teste.com" target="_blank">
                here
              </a>
            </p>
          </WrapperText>
        </WrapperForm>

        <Wrapper image={imgHome}></Wrapper>
      </Grid>
    </Container>
  )
}

export default Page1
