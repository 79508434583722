import styled from "styled-components";

type Props = {
  color: any;
  fontColor: any;
};

export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
`;

export const Grid = styled.div`
  display: flex;
  gap: 20px;
  min-height: calc(100vh - 80px);

  background-color: #f2ebaf !important;
  flex-direction: row;
  /* position: relative; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Wrapper = styled.div<Props>`
  background-color: ${(props) => props.color};
  border-radius: 25px;
  margin: 25px;
  min-height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 428px;
  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
    /* padding:5% 3% 0 0; */
    width: 100% !important;
    /* padding: 40px; */
    margin: 10px;
  }
`;

export const PFirst = styled.p`
  font-family: "avenir_nextregular";
  font-size: 16px;
  /* padding-right: 150px; */
  max-width: 407px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
  }
`;
export const PSecond = styled.p`
  font-family: "avenir_nextbold";
  font-size: 50px;
  line-height: 50px;
  max-width: 407px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    max-width: 375px;
    margin: 0px;
  }
`;
export const DivText = styled.div<Props>`
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.fontColor};

  @media screen and (max-width: 1050px) {
    padding: 0 20px 0 20px;
  }

  h4 {
    font-family: "avenir_nextregular";
    font-size: 20px;
    line-height: 27px;
    text-align: left;
    color: ${(props) => props.fontColor};
  }
  p {
    font-family: "avenir_nextbold";
    font-size: 45px;
    line-height: 50px;
    text-align: left;
    color: ${(props) => props.fontColor};
  }
  span {
    font-family: "avenir_nextregular";
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    color: ${(props) => props.fontColor};
  }
`;
