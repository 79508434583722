//@ts-nocheck
import React, { useEffect, useState } from 'react';

import { DivText, Grid, Container, DivImg, Wrapper } from './styles';

import apple from '../../../assets/images/apple.png';
import android from '../../../assets/images/android.png';

const Page2: React.FC = () => {
  return (
    <Container>
      <Grid>
        <DivText>
          <Wrapper>
            <p>Home services insured and guaranteed.</p>
            <p>
              With Xgreen, you’ll enjoy personalized services designed to meet
              your unique needs. Experience the difference with our expert team.
            </p>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.location.href = '/service';
              }}
            >
              Learn more about our offerings
            </button>
            <br />
          </Wrapper>
        </DivText>
        {/* <DivImg style={{height:'100%'}}>
        <img src={imgHome}/>
      </DivImg> */}
      </Grid>
    </Container>
  );
};

export default Page2;
