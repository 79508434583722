import styled from 'styled-components';

type Props = {
  image: any;
};

export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 100vh !important;
  min-width: 100vw;
`;

export const Grid = styled.div`
  background-color: #ffffff;
  display: inline-grid;
  grid-template-columns: 50% 50%;
  grid-gap: 5px;
  min-height: 100vh;
  width: 100%;

  /* position: relative; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const DivText = styled.div`
  padding-left: 25%;
  width: 100%;
  @media screen and (max-width: 800px) {
    padding: 0px 12px 0px 12px;
  }
`;
export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    /* background-image: url(${(p) => p.image}); */
    background-size: contain;
    /* width: 650px; */
    /* height: 404px; */
    background-repeat: no-repeat;
  }
  h5 {
    display: none;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    padding: 5% 3% 0 0;
    button {
      width: 95% !important;
      margin-right: 12px;
    }

    img {
      /* background-image: url(${(p) => p.image}); */
      background-size: cover;
      width: 95%;
      height: 100%;
      margin: 10%;
      background-repeat: no-repeat;
    }

    p {
      display: none;
    }

    h5 {
      display: inline;
      font-family: 'avenir_nextbold';
      font-size: 26px;
      line-height: 31px;
      margin: 50px 12px 0 12px;
    }
  }

  p:nth-of-type(1) {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #000;
  }
`;

export const WrapperIcon = styled.div`
  margin-right: 30px;
  h5 {
    font-family: 'avenir_nextbold';
    font-size: 45px;
    line-height: 50px;
  }

  @media screen and (max-width: 800px) {
    h5 {
      display: none;
    }
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;

export const DivIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    width: 60%;
    justify-content: center !important;
    align-items: center !important;
    display: flex;
  }
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;

  img {
    width: 45px;
    height: 45px;
  }
  p:nth-of-type(1) {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    margin: 15px 0 0 0 !important;
    padding: 0px !important;
  }
  p:nth-of-type(2) {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    padding: 0px !important;
    margin: 5px;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const IconMobile = styled.div`
  display: none;

  img {
    width: 26px;
    height: 26px;
  }
  p:nth-of-type(1) {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    line-height: 27px;
    text-align: center;
  }
  p:nth-of-type(2) {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    line-height: 21px;
    text-align: center;
  }

  @media screen and (max-width: 800px) {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;
