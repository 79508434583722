import styled from "styled-components";

type Props = {
  image: any;
  imageMobile: any;
};
export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 15px;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #549494;
  grid-template-areas: "a b";
  @media screen and (max-width: 1050px) {
    p {
      display: block;
      font-family: "avenir_nextbold";
      font-size: 45px;
      text-align: left;
      line-height: 50px;
      margin: 30px 10px 0 10px;
    }
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* div:nth-of-type(1) {
      order: 2;
    }
    div:nth-of-type(2) {
      order: 1;
    } */
  }
`;

export const PFirst = styled.p`
  font-family: "avenir_nextbold";
  font-size: 20px;
  /* padding-right: 150px; */
  max-width: 206px;
  text-align: left;
  line-height: 27px;
  margin-top: 20%;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    margin-left: 5%;
  }
`;
export const PSecond = styled.p`
  font-family: "avenir_nextbold";
  font-size: 50px;
  line-height: 50px;
  max-width: 206px;
  margin: 0px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    margin-left: 5%;
  }
`;

export const Wrapper = styled.div<Props>`
  min-height: 100%;

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
    background-position: bottom center;
    min-height: 364px;
    margin: 30px 10px 0 10px;
    /* background-image: url(${(props) => props.imageMobile}); */
  }
  @media screen and (max-width: 780px) {
    display: none !important;
  }
`;
export const WrapperForm = styled.div`
  margin: auto;
  p {
    font-family: "avenir_nextbold";
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    color: #fff;
  }

  h1 {
    font-family: "avenir_nextregular";
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    width: 542px;
    display: block;
    margin: 0px !important;
    padding-bottom: 20px;
    color: #fff;
  }
  small {
    font-family: "avenir_nextregular";
    font-size: 16px;
    text-align: left;
    line-height: 26px;
  }
  h3 {
    color: #707070;
    font-size: 16px;
    text-align: left;
    line-height: 26px;
    font-family: "avenir_nextbold";
  }

  @media screen and (max-width: 1050px) {
    width: 90%;
    margin-left: 3%;
    margin-top: 30px;
  }

  @media screen and (max-width: 780px) {
    width: 100% !important;
    margin-top: auto;
    h1 {
      width: 100%;
    }
    p {
      margin: 0px;
    }
  }
`;

export const ComboInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;

  @media screen and (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 95% !important;
    gap: 10px;
  }
`;
export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #ebebeb;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
  font-family: "avenir_nextregular";
  @media screen and (max-width: 1050px) {
    width: 100% !important;
  }
`;
export const Button = styled.button`
  min-width: 318px;

  justify-content: center;
  background-color: #9dfdca;
  height: 54px;
  color: #000;
  border: none;
  font-size: 16px;
  font-family: "avenir_nextbold";
  cursor: pointer;
  margin-bottom: 80px;
  @media screen and (max-width: 1050px) {
    width: auto;
    /* justify-content: center; */
    margin-bottom: 30px;
    margin-left: 10%;
  }
`;
export const Select = styled.select`
  width: 100%;
  height: 45px;
  background-color: #ebebeb;
  color: gray;
  border-radius: 0.375rem;
  font-size: 14px;
  border: none;
  padding-inline: 8px;
  font-family: "avenir_nextregular";
  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
  background-clip: padding-box;
  border: 1px solid #ced4da;

  @media screen and (max-width: 1050px) {
    width: 108%;
  }
`;

export const ButtonImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 318px;
  height: 62px;
  border: 1px solid #fff;
  color: #fff;
  gap: 10px;
  cursor: pointer;
  img {
    width: 42px;
    height: 53px;
    align-self: center;
  }
  p {
    font-family: "avenir_nextbold";
    font-size: 26px;
    text-align: left;
    line-height: 50px;
    color: #fff;
    align-self: center;
  }
`;
