import React from 'react';
import { Link } from 'react-router-dom';
import {
  SidebarContainer,
  Icon,

  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  WrapperButton,
  ButtonWhite,
  ButtonBlack,
} from '../Sidebar/SidebarElements';
import './styles.css';
import close from '../../assets/images/x.svg';
import { ButtonPurple } from '../Footer/styles';

import icon from '../../assets/loginDashboard_.png'
import { IoCloseSharp } from "react-icons/io5";
import { WrapperBar } from '../Navbar/NavbarElements';
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        {/* <img src={close} className="close" /> */}
        <IoCloseSharp color="#fff" size={32} />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>

          <SidebarLink onClick={toggle}>
            <Link to="/">ABOUT US</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/service">SERVICES</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/location">LOCATIONS</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/customer">CUSTOMERS</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/work">PROVIDERS</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/goal">OUR GOALS</Link>
          </SidebarLink>

          <SidebarLink onClick={toggle}>
            <Link to="/faq">FAQ</Link>
          </SidebarLink>

          <WrapperButton>
            <Link to='/customer'>
              <ButtonWhite onClick={() => window.open('https://dashboard.xgreen.app', "_blank")}>
                BOOK NOW
              </ButtonWhite>
            </Link>
            <Link to='/refer'>
              <ButtonPurple style={{ width: '290px' }}>
                EARN $50
              </ButtonPurple>
            </Link>

            <Link to="/" onClick={() => window.open('https://dashboard.xgreen.app', "_blank")}>
              <img src={icon} style={{ width: '48px', height: '48px' }} />
            </Link>

          </WrapperButton>

        </SidebarMenu>

      </SidebarWrapper>

    </SidebarContainer>
  );
};

export default Sidebar;
