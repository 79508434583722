//@ts-nocheck
import React, { useEffect, useState } from 'react';
// import Container from '@mui/material/Container';
// import { makeStyles, Theme } from '@mui/styles';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import { styled } from '@mui/material/styles';
// import goey from '../../../assets/images/home/page5/goeyja_MotoGrupo.png';
// import { FormControl } from '@mui/material';
// import { Col, Container, Row } from 'react-bootstrap';

import {
  DivText,
  Grid,
  Container,
  DivImg,
  P,
  PFirst,
  PSecond,
  Wrapper,
  IMG,
  NewMessage,
  NewStrong,
} from './styles';
import imgHome from '../../../assets/images/home/img_homeServices.png';
import apple from '../../../assets/images/apple.png';
import android from '../../../assets/images/android.png';

const Page1: React.FC = () => {
  return (
    <Container>
      <Grid>
        <DivText>
          <Wrapper>
            {/* <PFirst>Get your quote.</PFirst> */}
            <PSecond>All of the services your home needs.</PSecond>
            <NewMessage>
              <NewStrong>No matter the country.</NewStrong> Xgreen is a global
              solution for those who own a house in{' '}
              <NewStrong>Orlando/FL-US area.</NewStrong> Pay in your local
              currency through our App.
            </NewMessage>
          </Wrapper>

          <DivImg>
            <img
              src={android}
              onClick={() => {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.mindconsulting.xgreen&hl=en',
                  '_blank'
                );
              }}
            />
            <img
              src={apple}
              onClick={() => {
                window.open(
                  'https://apps.apple.com/br/app/xgreen/id6473839103',
                  '_blank'
                );
              }}
            />
          </DivImg>
        </DivText>
        <div style={{ height: '100%', border: 'none' }}>
          <IMG image={imgHome} />
          {/* <img src={imgHome} /> */}
        </div>
      </Grid>
    </Container>
  );
};

export default Page1;
