import styled from "styled-components";

type Props = {
  image: any;
};

export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
`;

export const Grid = styled.div<Props>`
  /* background-image: url(${(props) => props.image}); */
  background-color: #9dfdca;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 5px;
  min-height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* position: relative; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    background-size: cover;
    background-position: center bottom;
    min-height: calc(100vh - 80px);
  }
`;
export const DivText = styled.div`
  /* width: 100%; */
  @media screen and (max-width: 800px) {
    padding: 0px 12px 0px 12px;
  }
`;
export const Wrapper = styled.div`
  p:nth-of-type(1) {
    max-width: 542px;
    font-size: 45px;
    font-family: "avenir_nextbold";
    line-height: 50px;
    color: #000 !important;
  }

  p:nth-of-type(2) {
    max-width: 654px;
    font-family: "avenir_nextregular";
    font-size: 35px;
    color: #000 !important;
    line-height: 31px;
  }

  button:nth-of-type(1) {
    width: 430px;
    border-radius: 15px;
    justify-content: center;
    color: #9dfdca;
    height: 65px;
    background-color: #000;
    font-size: 26px;
    font-family: "avenir_nextbold";
    border: none;
    cursor: pointer;
  }

  @media screen and (max-width: 1000px) {
    padding: 5% 3% 0 0;
    button {
      width: 95% !important;
      margin-right: 12px;
      font-size: 16px !important;
      height: 45px !important;
    }

    p:nth-of-type(1) {
      /* max-width: 366px; */
      font-size: 35px;
      font-family: "avenir_nextbold";
      line-height: 40px;
      color: #9dfdca;
    }

    p:nth-of-type(2) {
      /* max-width: 366px; */
      font-family: "avenir_nextbold";
      font-size: 26px;
      color: #fff;
      line-height: 31px;
    }
  }
`;

export const DivImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
