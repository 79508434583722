import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import google from '../../../assets/google.png';
import ios from '../../../assets/ios.png';
const GlobalStyle = createGlobalStyle`
  body {
    background-color: black;
    color: white;
    font-family: 'avenir_nextregular';
  }
`;

const Container = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 16px;
`;

const MainContent = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
  font-family: 'avenir_nextregular';
`;

const Content = styled.div`
  margin: auto;
  @media screen and (max-width: 600px) {
    margin: 10px;
  }
`;

const Title = styled.h1`
  font-size: 55px;
  font-weight: bold;
  color: #9dfdca; // Tailwind's green-400
  margin-bottom: 16px;
`;

const Paragraph = styled.p`
  margin-bottom: 32px;
  padding-top: 16px;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #9dfdca; // Tailwind's green-400
`;

const Sidebar = styled.div`
  margin-top: 32px;
  @media (min-width: 768px) {
    width: 33.333333%;
    padding-left: 32px;
    margin-top: 0;
  }
`;

const SidebarContent = styled.div`
  padding: 10px;
  border-radius: 8px;
`;

const SectionTitle = styled.h2`
  color: #9dfdca; // Tailwind's green-400
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 16px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 32px;
`;

const Image = styled.img`
  width: 150px;
  height: 50px;
`;

const RulesContainer = styled.div`
  max-width: 1280px;
  margin: auto;
  padding: 15px;
  @media screen and (max-width: 600px) {
    margin: 10px;
  }
`;

const RulesTitle = styled.h2`
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 8px;
`;

const RuleParagraph = styled.p`
  font-size: 16px;
  line-height: 26px;
  /* margin-bottom: 8px; */
`;

const Page2 = () => {
  return (
    <>
      <GlobalStyle />
      <Container>
        <MainContent>
          <Content>
            <Title>Earn an easy $50 just by referring new clients.</Title>
            <Paragraph>
              At Xgreen, you can refer clients for our residential cleaning
              services, and once the service is completed and payment is
              received, you’ll earn <Highlight>$50</Highlight> for each
              successful referral.
            </Paragraph>
          </Content>
          <Sidebar>
            <SidebarContent>
              <SectionTitle>#1 Download the Xgreen App</SectionTitle>
              <Paragraph>
                Choose your preferred store and download our App, it's quick,
                easy and free.
              </Paragraph>
              <FlexContainer>
                <Image
                  src={google}
                  alt="Google Play"
                  onClick={() => {
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.mindconsulting.xgreen&hl=en',
                      '_blank'
                    );
                  }}
                />
                <Image
                  src={ios}
                  alt="App Store"
                  onClick={() => {
                    window.open(
                      'https://apps.apple.com/br/app/xgreen/id6473839103',
                      '_blank'
                    );
                  }}
                />
              </FlexContainer>
              <SectionTitle>
                #2 Create your account and starts referring friends/relatives
              </SectionTitle>
              <Paragraph>
                Create a customer account in just a few seconds and start
                referring, the more you refer the more chances you have of
                winning.
              </Paragraph>
              <SectionTitle>#3 Time to earn</SectionTitle>
              <Paragraph>
                If your referral successfully performed a service, after payment
                you will receive $50. One payment per valid customer.
              </Paragraph>
            </SidebarContent>
          </Sidebar>
        </MainContent>
      </Container>
      <RulesContainer>
        <RulesTitle>
          Rules and Conditions for Referring Customers to Xgreen.
        </RulesTitle>
        <RuleParagraph>
          1. Voluntary Referrals: Customers may refer Xgreen’s services to
          potential consumers. These referrals are neither obligatory nor
          exclusive. Making a referral does not create any formal relationship
          between the customer and Xgreen. Referrals are entirely voluntary and
          unconditional, and do not establish any employment or service
          provision relationship between the parties. Customers are free to
          refer others at any time and there is no minimum number of referrals
          required. 2. Referral Process and Payment: When a referral is made
          using a code sent electronically (via a link), the recipient can apply
          this code on the Xgreen app or website at the time of booking
          services. The referring customer will receive a payment of $50 through
          a digital platform on the Friday following the recipient’s payment for
          the contracted services. 3. Single Use Benefit: A referral code can
          only be used once by each recipient, regardless of the source of the
          code. If the recipient has already benefited from another referral
          code, the referring customer will not be entitled to any payment. 4.
          Address-Based Validation: The eligibility of a recipient to use a
          referral code is determined based on the service address. Once a
          referral code has been used for a specific address, no other code can
          be applied to that same address. 5. Campaign Duration and Changes: The
          $50 referral campaign is valid for 90 days starting from August 1,
          2024. Xgreen reserves the right to alter the referral amounts without
          prior notice; however, such changes will not affect payments that are
          already due. Updated referral amounts will be published on the Xgreen
          website. 6. The codes generated will be valid for 90 days, after this
          period they will expire.
        </RuleParagraph>
      </RulesContainer>
    </>
  );
};

export default Page2;
