//@ts-nocheck
import React from 'react'

import imgHome from '../../../assets/images/goal/savedTrees@2x.png'
import imgHomeMobile from '../../../assets/images/goal/savedTrees@2x.png'
import tree from '../../../assets/images/goal/smallTree_.png'

import { ButtonImg, Container, Grid, Wrapper, WrapperForm } from './styles'
const Page3: React.FC = () => {
  return (
    <Container>
      <Grid>
        <Wrapper image={imgHome} imageMobile={imgHomeMobile}></Wrapper>

        <WrapperForm>
          <p>Sustainability</p>
          <h1>Download the Xgreen App to save trees every service you hire.</h1>
          <ButtonImg>
            <img src={tree} alt="tree" />
            <p>Trees Saved</p>
          </ButtonImg>
        </WrapperForm>
      </Grid>
    </Container>
  )
}

export default Page3
