import styled from 'styled-components'
import imgHome from '../../../assets/images/xgreen_bgEXTRA-7@2x.png'
export const Container = styled.div`
  box-sizing: border-box;
  min-height: 100vh !important;
  min-width: 100vw;
  background-image: url(${imgHome});
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 768px) {
    min-height: 92vh !important;
  }
  @media screen and (max-width: 1380px) {
    background-position: 25% 75%;
  }
`

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-gap: 5px;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 568px) {
    min-height: 90vh !important;
  }
`
export const DivText = styled.div`
  margin-right: 10%;

  @media screen and (min-width: 800px) and (max-width: 1380px) {
    margin-right: 4%;
    p:nth-of-type(1) {
      font-size: 35px !important;
      line-height: 30px !important;
    }
    p:nth-of-type(2) {
      font-size: 18px !important;
      line-height: 22px !important;
    }
  }

  @media screen and (max-width: 800px) {
    padding: 0px 12px 0px 12px;
    margin: 0px;
  }
`
export const Wrapper = styled.div`
  p:nth-of-type(1) {
    max-width: 542px;
    font-size: 45px;
    font-family: 'avenir_nextbold';
    line-height: 50px;
    color: #b6d1b4;
  }

  p:nth-of-type(2) {
    max-width: 542px;
    font-family: 'avenir_nextregular';
    font-size: 20px;
    text-align: left;
    line-height: 26px;
    color: #fff;
  }

  button:nth-of-type(1) {
    width: 430px;
    justify-content: center;
    background-color: #9dfdca;
    border: transparent;
    height: 65px;
    color: #000;
    font-size: 26px;
    font-family: 'avenir_nextbold';
  }

  button:nth-of-type(2) {
    width: 430px;
    border-radius: 15px;
    justify-content: center;
    background-color: #fff;
    height: 65px;
    color: #000;
    font-size: 26px;
    font-family: 'avenir_nextbold';
    border: none;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    padding: 0px;

    button {
      width: 95% !important;
      margin-right: 12px;
    }

    p:nth-of-type(1) {
      max-width: 366px;
      font-size: 26px;
      font-family: 'avenir_nextbold';
      line-height: 31px;
      display: none;
    }
    p:nth-of-type(2) {
      max-width: 366px;
      font-family: 'avenir_nextregular';
      font-size: 16px;
      display: none;
    }
    button {
      font-size: 16px !important;
      width: 100% !important;
    }
  }

  @media screen and (max-width: 568px) {
    button {
      margin-top: 50vh !important;
    }
  }
`

export const DivImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 800px) {
    img {
      width: 100%;
    }
  }
`
