import styled from 'styled-components';

type Props = {
  image: any;
};
export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 80vh !important;
  min-width: 100vw;
  color: #000;
`;

export const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: 42% 58%;
  grid-gap: 5px;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #ffffff;

  /* position: relative; */
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    div:nth-of-type(1) {
      order: 2;
    }
    div:nth-of-type(2) {
      order: 1;
    }
  }
`;
export const DivText = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  padding-left: 25%;

  @media screen and (max-width: 1050px) {
    padding-left: 12px;
  }
`;
export const Wrapper = styled.div`
  /* padding:20% 20% 0 20%; */
  /* padding-top:183px; */
  /* padding-top:25%; */
  height: auto !important;
  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr 1fr;
    padding: 5% 3% 0 0;
  }
`;
export const DivImg = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 20px;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 40% 40%;
    padding-left: 1%;
    padding-bottom: 40px;
  }
`;
export const PFirst = styled.p`
  font-family: 'avenir_nextbold';
  font-size: 45px;
  /* padding-right: 150px; */
  max-width: 318px;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
  }
`;
export const PSecond = styled.p`
  font-family: 'avenir_nextbold';
  font-size: 155px;
  line-height: 50px;
  max-width: 430px;
  text-align: left;
  @media screen and (max-width: 1050px) {
    padding-right: 0px;
  }
`;

export const IMG = styled.div<Props>`
  background-image: url(${(p) => p.image});
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  @media screen and (max-width: 1050px) {
    background-size: contain;
    justify-content: center;
    display: flex;
    min-height: 340px;
  }
  @media screen and (max-width: 600px) {
    background-size: cover;
    justify-content: center;
    display: flex;
    min-height: 340px;
  }
`;

export const SmallText = styled.p`
  font-family: 'avenir_nextregular';
  font-size: 20px;
  line-height: 0px;
  text-align: left;
  padding-top: 15px !important;
  margin-bottom: 30px;
`;
