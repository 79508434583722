//@ts-nocheck
import React from 'react'

import {
  Grid,
  Container,
  Wrapper,
  WrapperIcon,
  DivIcon,
  Icon,
  IconMobile,
} from './styles'
import img from '../../../assets/images/home/page3/cars_.png'

import sticker from '../../../assets/images/home/page3/icon/sticker.png'
import sad from '../../../assets/images/home/page3/icon/sad-face.png'
import timer from '../../../assets/images/home/page3/icon/timer.png'

const Page3: React.FC = () => {
  const data = [
    {
      img: sticker,
      title: 'Get a price.',
      text: 'Tell us about your properties and needs.',
    },
    {
      img: sad,
      title: 'Book it.',
      text: 'We’ll make your home shine like the sun.',
    },
    {
      img: timer,
      title: 'Relax and smile.',
      text: 'The house is clean! Let go and enjoy it.',
    },
  ]
  return (
    <Container>
      <Grid>
        <Wrapper>
          <img src={img} alt="xgreen" />
          <p>
            Xgreen uses electric and green vehicles to move.
            <br /> Respect for the planet is our motto.
          </p>
        </Wrapper>

        <WrapperIcon>
          <h5>
            Xgreen is environmentally
            <br /> friendly and good for you.
          </h5>
          <DivIcon>
            {data.map((value: any, index: number) => {
              return (
                <div key={index}>
                  <Icon>
                    <img src={value.img} alt="xgreen" />
                    <p>{value.title}</p>
                    <p>{value.text}</p>
                  </Icon>
                  <IconMobile>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <img src={value.img} alt="xgreen" />
                      <p>{value.title}</p>
                    </div>

                    <p
                      style={{
                        marginTop: '0px',
                        fontFamily: 'avenir_nextregular',
                        textAlign: 'start',
                      }}
                    >
                      {value.text}
                    </p>
                  </IconMobile>
                </div>
              )
            })}
          </DivIcon>
        </WrapperIcon>
      </Grid>
    </Container>
  )
}

export default Page3
