import styled from 'styled-components';
import xgreenpeople from '../../../assets/xgreen_people@2x.png';

export const Container = styled.div`
  /* box-sizing: border-box; */
  min-height: 70vh !important;
  min-width: 100vw;
  background-image: url(${xgreenpeople});
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column; /* ou 'row', dependendo do layout desejado */
  height: 100%;
  @media screen and (max-width: 780px) {
    object-fit: cover !important;
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 700px !important;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;
  p {
    width: 542px;
    height: 88px;
    font-family: 'avenir_nextregular';
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    @media screen and (max-width: 780px) {
      width: auto;
      height: auto;
      font-size: 22px;
    }
  }
  h5 {
    color: #9dfdca;
    font-family: 'avenir_nextbold';
    font-size: 45px;
    text-align: left;
    line-height: 50px;
    margin: 0px;
    @media screen and (max-width: 780px) {
      font-size: 42px;
    }
  }
  padding-left: 10%;
  @media screen and (max-width: 780px) {
    padding-left: 10px;
  }
`;

export const ComboMobile = styled.div`
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  /* padding:90px 0 0 50px; */
  background-color: #fff;
  border-radius: 25px;
  p {
    font-family: 'avenir_nextbold';
    font-size: 20px;
    text-align: left;
    line-height: 27px;
    padding: 10px 10px 0px 25px !important;
  }
  span {
    font-family: 'avenir_nextregular';
    font-size: 16px;
    text-align: left;
    line-height: 20px;
    padding: 10px 10px 30px 25px !important;
  }
  @media screen and (max-width: 780px) {
    margin-bottom: 60px;
  }
`;
